






































































































































































































































import { debounce } from "@/helpers/debounce";
import { useDate } from "@/hooks";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { DataResponseReportDebtCardLeasing } from "@/models/interface/apPayment.interface";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataResponseListLeasing } from "@/models/interface/leasing.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { apPaymentServices } from "@/services/apPayment.service";
import { contactServices } from "@/services/contact.service";
import { leasingServices } from "@/services/leasing.service";
import { logisticServices } from "@/services/logistic.service";
import { settingsServices } from "@/services/settings.service";
import { currencyFormat } from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class KartuHutangLeasing extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form!: WrappedFormUtils;
  totalData = 0;
  page = 1;
  limit = 10;
  dataSource = [] as DataResponseReportDebtCardLeasing[];
  lessorId = "";
  dataReport = {
    lessorName: "-",
    lessorAddress: "-",
    beginingBalance: 0 as string | number,
    endingBalance: 0 as string | number,
  };
  loading = {
    branch: false,
    find: false,
    download: false,
    currency: false,
    leasingNumber: false,
    lessorName: false,
  };
  dataBranch = [] as DataWarehouseBranch[];
  dataCurrency = [] as DataMasterCurrency[];
  dataLessorName = [] as ContactData[];
  dataLeasingNumber = [] as DataResponseListLeasing[];
  downloadParams = {
    branch: "",
    supplierName: "",
    currency: "",
    companyName: "",
    transactionDate: "",
    leasingNumber: "",
  };
  columnsTable = [
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branchName",
      width: 150,
    },
    {
      title: this.$t("lbl_leasing_date"),
      dataIndex: "date",
      key: "date",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_document_reference"),
      dataIndex: "documentReference",
      key: "documentReference",
      width: 180,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_leasing_contract_number"),
      dataIndex: "leasingContractNumber",
      key: "leasingContractNumber",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_currency"),
      dataIndex: "leasingCurrency",
      key: "leasingCurrency",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_rates"),
      dataIndex: "leasingRates",
      key: "leasingRates",
      width: 150,
      // scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_leasing_amount_idr"),
      dataIndex: "total",
      key: "total",
      width: 150,
      // scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_payment_number"),
      dataIndex: "documentNo",
      key: "documentNo",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_payment_date"),
      dataIndex: "transactionDate",
      key: "transactionDate",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_payment_amount"),
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: 150,
      // scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_balance"),
      dataIndex: "endBalance",
      key: "endBalance",
      width: 150,
      // scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
  ];
  formRules = {
    branch: {
      label: "lbl_branch",
      name: "lbl_branch",
      placeholder: "lbl_branch_placeholder",
      decorator: ["branch"],
    },
    lessorName: {
      label: "lbl_lessor_name",
      name: "lessorName",
      placeholder: "lbl_lessor_name_placeholder",
      decorator: [
        "lessorName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    currency: {
      label: "lbl_currency",
      name: "lbl_currency",
      placeholder: "lbl_currency_placeholder",
      decorator: ["currency"],
    },
    billToAddress: {
      label: "lbl_bill_to_address",
    },
    beginingBalance: {
      label: "lbl_begining_balance",
    },
    totalInvoiceOutstanding: {
      label: "lbl_total_invoice_outstanding",
    },
    endingBalance: {
      label: "lbl_ending_balance",
    },
    transactionDate: {
      label: "lbl_transaction_date_from",
      name: "lbl_transaction_date_from",
      placeholder: "lbl_transaction_date_from_placeholder",
      decorator: ["transactionDate"],
    },
    leasingNumber: {
      label: "lbl_leasing_number",
      name: "leasingNumber",
      placeholder: "lbl_leasing_number_placeholder",
      decorator: ["leasingNumber"],
    },
  };

  handleChangeLessor(lessorId) {
    this.lessorId = lessorId;
    this.getLeasingNumber("");
  }

  getBranch(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loading.branch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.branch = false));
  }

  getLessorName(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `supplier~true_AND_active~true_AND_supplierData.supplierType~*Leasing*`,
    };
    if (valueSearch)
      params.search = `supplierData.supplierType~*Leasing*_AND_supplier~true_AND_active~true_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;

    this.loading.lessorName = true;
    contactServices
      .listContactData(params)
      .then(res => {
        this.dataLessorName = res.data;
      })
      .finally(() => (this.loading.lessorName = false));
  }

  getCurrency(valueSearch) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (valueSearch) params.search = `currencyCode~${valueSearch}`;
    this.loading.currency = true;
    settingsServices
      .listOfMasterCurrency(params, "")
      .then(response => (this.dataCurrency = response.data))
      .finally(() => (this.loading.currency = false));
  }

  getLeasingNumber(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch) params.search = `leasingNumber~*${valueSearch}*`;
    if (this.lessorId && valueSearch)
      params.search = `leasingNumber~*${valueSearch}*_AND_lessor.secureId~*${this.lessorId}*`;
    else if (this.lessorId)
      params.search = `lessor.secureId~*${this.lessorId}*`;
    this.loading.leasingNumber = true;
    leasingServices
      .listLeasing(params)
      .then(response => {
        this.dataLeasingNumber = response.data;
      })
      .finally(() => (this.loading.leasingNumber = false));
  }

  assignSearch(key, value, and): string {
    const { toStartDay, toEndDay } = useDate();
    if (key === "branch" && value) return and + `branchId~*${value}*`;
    // else if (key === "lessorName" && value) return and + `lessorId~*${value}*`;
    else if (key === "currency" && value)
      return (
        and +
        `leasingCurrency~*${
          this.dataCurrency.find(dataFind => dataFind.id === value)
            ?.currencyCode
        }*`
      );
    else if (key === "transactionDate" && value && value.length > 0) {
      return (
        and +
        `date>=${toStartDay(value[0]).format()}_AND_date<=${toEndDay(
          value[1]
        ).format()}`
      );
    } else if (key === "leasingNumber" && value)
      return and + `description~*${value}*`;
    else return "";
  }
  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  checkValue(value): string {
    if (value && !value.includes("undefined")) {
      return value;
    } else {
      return ",ALL";
    }
  }

  checkParams(res): string {
    let params = "";
    // company is mandatory
    params += this.$store.state.authStore.authData.companyName;
    params += this.checkValue(
      `,${
        this.dataBranch.find(dataFind => dataFind.id === res["branch"])?.name
      }`
    );
    params += this.checkValue(
      `,${this.dataLessorName
        .find(dataFind => dataFind.id === res["lessorName"])
        ?.fullName?.replace(",", "")}`
    );
    params += this.checkValue(
      `,${
        this.dataCurrency.find(dataFind => dataFind.id === res["currency"])
          ?.currencyCode
      }`
    );
    params += this.checkValue(
      `,${
        res["transactionDate"] &&
        res["transactionDate"].length > 0 &&
        res["transactionDate"][0]
          ? moment(res["transactionDate"][0]).format(DEFAULT_DATE_FORMAT)
          : undefined
      }`
    );
    params += this.checkValue(
      `,${
        res["transactionDate"] &&
        res["transactionDate"].length > 0 &&
        res["transactionDate"][1]
          ? moment(res["transactionDate"][1]).format(DEFAULT_DATE_FORMAT)
          : undefined
      }`
    );
    params += this.checkValue(`,${res["leasingNumber"]}`);
    return params;
  }

  searchData(value, type) {
    switch (type) {
      case "branch":
        debounce(() => this.getBranch(value), 400);
        break;
      case "lessorName":
        debounce(() => this.getLessorName(value), 400);
        break;
      case "currency":
        debounce(() => this.getCurrency(value), 400);
        break;
      case "leasingNumber":
        debounce(() => this.getLeasingNumber(value), 400);
        break;
      default:
        break;
    }
  }

  handleDownload(params, value) {
    params.params = this.checkParams(value);
    this.loading.download = true;
    apPaymentServices
      .downloadReportDebtCardLeasing(params, this.lessorId)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report_debt_card_leasing.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => (this.loading.download = false));
  }

  checkDate(value): string {
    if (value) return moment(value).format(DEFAULT_DATE_FORMAT);
    return "";
  }

  handleFind(params) {
    this.loading.find = true;
    apPaymentServices
      .listReportDebtCardLeasing(params, this.lessorId)
      .then(response => {
        this.dataReport.lessorName = response.lessorName;
        this.dataReport.lessorAddress = response.lessorAddress;
        this.dataReport.beginingBalance = currencyFormat(
          response.beginingBalance || 0
        );
        this.dataReport.endingBalance = currencyFormat(
          response.endingBalance || 0
        );
        this.dataSource = response.data.map((dataMap, idxParent) => {
          return {
            ...dataMap,
            key: idxParent,
            children: dataMap.children.map((dataChildren, idxChild) => {
              return {
                ...dataChildren,
                key: `${idxParent}${idxChild}`,
                date: this.checkDate(dataChildren.date),
                transactionDate: this.checkDate(dataChildren.transactionDate),
                credit: currencyFormat(dataChildren.credit || 0),
                leasingCurrency: dataChildren.leasingCurrency
                  ? dataChildren.leasingCurrency
                  : dataChildren.paymentCurrency,
                leasingRates: currencyFormat(
                  dataChildren.leasingRates || dataChildren.paymentRates || 0
                ),
                total: currencyFormat(dataChildren.total || 0),
                totalAmount: currencyFormat(dataChildren.totalAmount || 0),
                endBalance: currencyFormat(dataChildren.endBalance || 0),
              };
            }),
            date: this.checkDate(dataMap.date),
            transactionDate: this.checkDate(dataMap.transactionDate),
          };
        });
        this.totalData = response.totalElements;
      })
      .finally(() => (this.loading.find = false));
  }

  onclickButton(type, triger) {
    if (type == "reset") {
      this.form.resetFields();
    } else {
      this.form.validateFields((err, value) => {
        if (!err) {
          const params: RequestQueryParamsModel = {
            page: this.page - 1,
            limit: this.limit,
            leasingNumber: value.leasingNumber,
            // lessorId: value.lessorName
          };
          if (triger === "No") {
            this.page = 1;
            params.page = 0;
          }
          params.search = this.dynamicSearch(value);
          switch (type) {
            case "find":
              this.handleFind(params);
              break;
            case "download":
              this.handleDownload(params, value);
              break;
            default:
              break;
          }
        }
      });
    }
  }

  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 1;
    this.onclickButton("find", "Yes");
  }
  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page;
    this.onclickButton("find", "Yes");
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "kartuHutangLeasing" });
  }
  mounted() {
    this.getBranch("");
    this.getLessorName("");
    this.getCurrency("");
    this.getLeasingNumber("");
  }
  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
