var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-content",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                { attrs: { title: _vm.$t("lbl_report_kartu_hutang_leasing") } },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form",
                            _vm._b(
                              { attrs: { layout: "vertical", form: _vm.form } },
                              "a-form",
                              _vm.formItemLayout,
                              false
                            ),
                            [
                              _c(
                                "a-row",
                                [
                                  _c(
                                    "a-col",
                                    {
                                      attrs: {
                                        span: 12,
                                        xs: 24,
                                        sm: 24,
                                        md: 12
                                      }
                                    },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.branch.label
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              directives: [
                                                {
                                                  name: "decorator",
                                                  rawName: "v-decorator",
                                                  value:
                                                    _vm.formRules.branch
                                                      .decorator,
                                                  expression:
                                                    "formRules.branch.decorator"
                                                }
                                              ],
                                              attrs: {
                                                name: _vm.formRules.branch.name,
                                                placeholder: _vm.$t(
                                                  _vm.formRules.branch
                                                    .placeholder
                                                ),
                                                showSearch: true,
                                                "option-filter-prop":
                                                  "children",
                                                "filter-option":
                                                  _vm.filterOption,
                                                loading: _vm.loading.branch,
                                                allowClear: true
                                              },
                                              on: {
                                                search: function(value) {
                                                  return _vm.searchData(
                                                    value,
                                                    "branch"
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.dataBranch, function(
                                              data,
                                              index
                                            ) {
                                              return _c(
                                                "a-select-option",
                                                {
                                                  key: index,
                                                  attrs: { value: data.id }
                                                },
                                                [
                                                  _c(
                                                    "a-tooltip",
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "title" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.name
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(data.name) +
                                                          " "
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.lessorName.label
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              directives: [
                                                {
                                                  name: "decorator",
                                                  rawName: "v-decorator",
                                                  value:
                                                    _vm.formRules.lessorName
                                                      .decorator,
                                                  expression:
                                                    "formRules.lessorName.decorator"
                                                }
                                              ],
                                              attrs: {
                                                name:
                                                  _vm.formRules.lessorName.name,
                                                placeholder: _vm.$t(
                                                  _vm.formRules.lessorName
                                                    .placeholder
                                                ),
                                                showSearch: true,
                                                "option-filter-prop":
                                                  "children",
                                                "filter-option":
                                                  _vm.filterOption,
                                                loading: _vm.loading.lessorName,
                                                allowClear: true
                                              },
                                              on: {
                                                search: function(value) {
                                                  return _vm.searchData(
                                                    value,
                                                    "lessorName"
                                                  )
                                                },
                                                change: _vm.handleChangeLessor
                                              }
                                            },
                                            _vm._l(_vm.dataLessorName, function(
                                              data,
                                              index
                                            ) {
                                              return _c(
                                                "a-select-option",
                                                {
                                                  key: index,
                                                  attrs: { value: data.id }
                                                },
                                                [
                                                  _c(
                                                    "a-tooltip",
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "title" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.fullName
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.fullName
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.currency.label
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              directives: [
                                                {
                                                  name: "decorator",
                                                  rawName: "v-decorator",
                                                  value:
                                                    _vm.formRules.currency
                                                      .decorator,
                                                  expression:
                                                    "formRules.currency.decorator"
                                                }
                                              ],
                                              attrs: {
                                                name:
                                                  _vm.formRules.currency.name,
                                                placeholder: _vm.$t(
                                                  _vm.formRules.currency
                                                    .placeholder
                                                ),
                                                showSearch: true,
                                                "option-filter-prop":
                                                  "children",
                                                "filter-option":
                                                  _vm.filterOption,
                                                loading: _vm.loading.currency,
                                                allowClear: true
                                              },
                                              on: {
                                                search: function(value) {
                                                  return _vm.searchData(
                                                    value,
                                                    "currency"
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.dataCurrency, function(
                                              data,
                                              index
                                            ) {
                                              return _c(
                                                "a-select-option",
                                                {
                                                  key: index,
                                                  attrs: { value: data.id }
                                                },
                                                [
                                                  _c(
                                                    "a-tooltip",
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "title" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.currencyCode
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.currencyCode
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.transactionDate
                                                .label
                                            )
                                          }
                                        },
                                        [
                                          _c("a-range-picker", {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value:
                                                  _vm.formRules.transactionDate
                                                    .decorator,
                                                expression:
                                                  "formRules.transactionDate.decorator"
                                              }
                                            ],
                                            attrs: {
                                              format: _vm.DEFAULT_DATE_FORMAT,
                                              placeholder: [
                                                _vm.$t("lbl_start_date"),
                                                _vm.$t("lbl_end_date")
                                              ]
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-model-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.leasingNumber.label
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              directives: [
                                                {
                                                  name: "decorator",
                                                  rawName: "v-decorator",
                                                  value:
                                                    _vm.formRules.leasingNumber
                                                      .decorator,
                                                  expression:
                                                    "formRules.leasingNumber.decorator"
                                                }
                                              ],
                                              attrs: {
                                                name:
                                                  _vm.formRules.leasingNumber
                                                    .name,
                                                placeholder: _vm.$t(
                                                  _vm.formRules.leasingNumber
                                                    .placeholder
                                                ),
                                                showSearch: true,
                                                "option-filter-prop":
                                                  "children",
                                                "filter-option":
                                                  _vm.filterOption,
                                                loading:
                                                  _vm.loading.leasingNumber,
                                                allowClear: true
                                              },
                                              on: {
                                                search: function(value) {
                                                  return _vm.searchData(
                                                    value,
                                                    "leasingNumber"
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(
                                              _vm.dataLeasingNumber,
                                              function(data, index) {
                                                return _c(
                                                  "a-select-option",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      value: data.leasingNumber
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "a-tooltip",
                                                      [
                                                        _c(
                                                          "template",
                                                          { slot: "title" },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.leasingNumber
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.leasingNumber
                                                            ) +
                                                            " "
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-space",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onclickButton("reset", "All")
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading.find
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onclickButton("find", "No")
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_find")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { staticClass: "mt-4", attrs: { span: 24 } },
                        [
                          _c(
                            "a-tabs",
                            { attrs: { type: "card" } },
                            [
                              _c(
                                "a-tab-pane",
                                {
                                  key: "1",
                                  staticClass: "bordertabs p-3",
                                  attrs: {
                                    tab: _vm.$t("lbl_payable_mutation_report")
                                  }
                                },
                                [
                                  _c(
                                    "a-row",
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.lessorName.label
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.dataReport.lessorName
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          attrs: {
                                            span: 20,
                                            xs: 24,
                                            sm: 16,
                                            md: 16,
                                            lg: 20
                                          }
                                        },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.billToAddress
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.dataReport.lessorAddress
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          attrs: {
                                            span: 4,
                                            xs: 24,
                                            sm: 6,
                                            md: 6,
                                            lg: 4
                                          }
                                        },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.beginingBalance
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.dataReport
                                                      .beginingBalance
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          staticClass: "mt-4",
                                          attrs: { span: 24 }
                                        },
                                        [
                                          _c("TableCustom", {
                                            attrs: {
                                              loading: _vm.loading.find,
                                              dataSource: _vm.dataSource,
                                              columns: _vm.columnsTable,
                                              scroll: {
                                                x: "calc(700px + 50%)",
                                                y: 400
                                              },
                                              paginationcustom: true,
                                              defaultPagination: false
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          staticClass: "mt-3",
                                          attrs: { span: 12 }
                                        },
                                        [
                                          _c("Pagination", {
                                            attrs: {
                                              total: _vm.totalData,
                                              pageSizeSet: _vm.limit,
                                              idPagination: "pagination1"
                                            },
                                            on: {
                                              "response-pagesize-change":
                                                _vm.responsePageSizeChange,
                                              "response-currentpage-change":
                                                _vm.responseCurrentPageChange
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          staticClass: "mt-3",
                                          attrs: { span: 12, align: "end" }
                                        },
                                        [
                                          _c(
                                            "a-tag",
                                            {
                                              staticStyle: {
                                                "font-size": "13px"
                                              },
                                              attrs: { color: "grey" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("lbl_total_found")
                                                  ) +
                                                  " : " +
                                                  _vm._s(_vm.totalData) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          staticClass: "mt-3",
                                          attrs: { span: 24, align: "end" }
                                        },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.endingBalance
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.dataReport.endingBalance
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          staticClass: "mt-3",
                          attrs: { span: 24, align: "end" }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.download
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onclickButton("download", "Yes")
                                }
                              }
                            },
                            [_vm._v(" Download ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }